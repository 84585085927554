import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private searchParamsSource = new BehaviorSubject('');
  private idsSource = new BehaviorSubject('');
  private pagesSource = new BehaviorSubject('');
  public currentPageSubject = new BehaviorSubject('');
  searchParams : Subject<any> = new Subject()
  ids : Subject<any> = new Subject()
  currentParams = this.searchParamsSource.asObservable();
  currentids= this.idsSource.asObservable();
  currentpage= this.pagesSource.asObservable();
  currentRolePages= this.currentPageSubject.asObservable();
  currentPage$: Observable<any>;

  constructor() { }

  storeSearchParams(params: any) {
    this.searchParamsSource.next(params)
  }
  storeIDs(amount: any) {
    this.idsSource.next(amount)
  }
  storePages(page){
    this.pagesSource.next(page)
  }
  storeRolePages(pages){
    this.currentPageSubject.next(pages)
  }
  get currentPageValue(): any {
    return this.currentPageSubject.value;
  }

  set currentPageValue(page: any) {
    this.currentPageSubject.next(page);
  }
  checkForAccess(routerLink){
    if (typeof this.currentPageValue == "string") {
      if (JSON.parse(this.currentPageValue).some((r) => r.includes(routerLink)))
        return true;
    } else {
      if (this.currentPageValue.some((r) => r.includes(routerLink)))
        return true;
    }
  }
}
