<div [ngClass]="{ container: true, 'rtl-container': lang === 'ar' }">
  <div *ngIf="getUrlData?.invoice?.receiver?.logo" class="text-center">
    <img class="company-logo" [src]="getUrlData?.invoice?.receiver?.logo" alt="copmany logo" />
  </div>
  <div class="row">
    <div class="col-12">
      <p class="invoice">{{ "Invoices" | translate }}</p>
    </div>
    <div class="col-12 d-flex" *ngIf="showInvices">
      <span class="invoice-span">{{
        "Select at least one invoice" | translate
        }}</span>
    </div>
    <div *ngIf="invoices.length > 0" class="d-flex selectAll">
      <input class="mx-2" type="checkbox" [checked]="isSelectedAll" name="pay" id="select" (change)="selectAll($event)" />
      <label for="select" class="rent font-weight-bold">
        {{ "Select All" | translate }}</label>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-7 col-sm-12 offset-lg-0">
      <div class="box-shadow mt-5 p-3" *ngIf="invoices == null || invoices.length < 1">
        <h1 class=" main-font">
          {{ "NO CURRENT INVOICES" | translate }}
        </h1>
      </div>

      <!-- <div *ngFor="let invoice of invoices; let i = index">
       <div *ngIf="invoice?.status !== '2' && !showInvices" class="container" id="{{ i }}">
          <div class="main-border">
            <div class="row">
              <div class="col ar-left">
                <span class="past {{
                    past_due(invoice?.end_date) ? 'past-col' : 'due-col'
                  }}">
                  {{
                  past_due(invoice?.end_date)
                  ? ("Past Due" | translate)
                  : ("Due" | translate)
                  }}
                </span>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12">
                <p class="main-font mid-font font-weight-bold">
                  <span class="">{{ "Unit Name" | translate }}</span>: {{ invoice?.unit_name }}
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="">{{ "Amount" | translate }}</span> :
                  {{ formateService.formatNum(total_amount) }}
                  {{ " LE " | translate }}
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="">{{ "Discount" | translate }}</span> :
                  {{ total_discount }} {{ " LE " | translate }}
                </p>
                <p [formGroup]="partialForm" *ngIf="partialChecked"
                  class="main-font mid-font font-weight-bold form-group">
                  <span class="">{{
                    "Partial Amount" | translate
                    }}</span>
                  :
                  <input type="number" (input)="partialChange($event, invoice)" [(ngModel)]="partial_amount"
                    [placeholder]="'0.00' | translate" formControlName="partial_amount" />
                  {{ " LE " | translate }}
                  <span *ngIf="partialForm.controls.partial_amount.touched">
                    <p class="status-danger" *ngIf="
                        partialForm.controls.partial_amount.errors?.pattern
                      ">
                      {{
                      "Partial Amount should be positive number" | translate
                      }}!
                    </p>
                    <p class="status-danger" *ngIf="
                        partialForm.controls.partial_amount.value >=
                        invoice.amount
                      ">
                      {{
                      "Partial Amount should be less than Total Amount"
                      | translate
                      }}!
                    </p>
                  </span>
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="">{{ "Period" | translate }}</span> :
                  {{ invoice?.start_date }} -
                  {{ invoice?.end_date }}
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="">{{
                    "Contract Name" | translate
                    }}</span>
                  :
                  {{ invoice?.contract_name }}
                </p>
                <div class="row">
                  <div class="col-md-6">
                    <p class="main-font mid-font font-weight-bold">
                      <span class="">{{
                        "Owner Name" | translate
                        }}</span>
                      :
                      {{ invoice?.owner }}
                    </p>
                  </div>
                  <div class="col-md-6 partial">
                    <input type="checkbox" disabled name="pay" id="partial" [(ngModel)]="partialChecked"
                      (change)="onChangeShow($event)" />
                    <label for="partial" class="rent font-weight-bold">
                      {{ "Use partial payment" | translate }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      <!-- <div *ngIf="invoice?.status == '2'" class="paid-invoice">
          {{ "Invoice already paid" | translate }}
        </div> -->
      <!-- <div *ngIf="!showInvices && invoice?.status !== '2'" class="btn pl-5">
          <button [disabled]="invoicesCount <= 1" (click)="showAllInvices()" type="button" class="btn bg-color-orange">
            {{ "Show All Invoices" | translate }}
            <i class="fa fa-bell position-relative">
              <span *ngIf="invoicesCount > 1" class="translate-middle badge rounded-pill bg-danger" class="{{
                  lang === 'en' ? 'badge' : 'badgertl'
                }} translate-middle  rounded-pill bg-danger">
                {{ invoicesCount }}
              </span>
            </i>
          </button>
        </div>
        <div *ngIf="showInvices" class="btn pl-5">
          <button (click)="showAllInvices()" type="button" class="goBack">
            {{ "Go Back" | translate }}
          </button>
        </div>
      </div> -->

      <div *ngFor="let invoice of invoices; let i = index">
        <div>
          <div class="main-border pt-0 layout">
            <div class=" main-header">
              <div class="">
                <input [disabled]="invoice?.status?.active === false" type="checkbox" name="pay" id="check{{ i }}"
                  [(ngModel)]="invoice.isChecked" (change)="selectInvoiceCard($event, i, invoice)"
                  [checked]="invoice?.status?.checked" />
                <!-- <label for="check{{ i }}" class="rent font-weight-bold">
                  {{ invoice?.contract_type | translate }}</label><br /> -->
              </div>
              <div class="">
                <span class="past {{
                    past_due(invoice?.date_to) ? 'past-col' : 'due-col'
                  }}">
                  {{
                  past_due(invoice?.date_to)
                  ? ("Past Due" | translate)
                  : ("Due" | translate)
                  }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 px-5">
                <!-- <p *ngIf="invoice?.unit_name" class="main-font mid-font font-weight-bold">
                  <span class="">{{ "Unit Name" | translate }}:</span> {{ invoice?.unit_name }}
                </p>
                <p *ngIf="invoice?.location" class="main-font mid-font font-weight-bold">
                  <span class="">{{ "Location" | translate }}:</span> {{ invoice?.location }}
                </p> -->
                <p class="main-font mid-font font-weight-bold">
                  <span class="">{{ "Payer Name" | translate }}:</span> {{ invoice?.payer_name }}
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="">{{ "Invoice Type" | translate }}:</span>
                  {{ invoice?.invoice_type||'-' }}
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="">{{ "Amount" | translate }}:</span>
                  {{
                  calculateTotal(invoice?.amount_remaining, invoice?.discount)
                  }}
                  {{ " LE " | translate }}
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="">{{ "Discount" | translate }}:</span>
                  {{ invoice?.discount || "0.00" }} {{ " LE " | translate }}
                </p>
                <p [formGroup]="partialForm" *ngIf="partialChecked"
                  class="main-font mid-font font-weight-bold form-group d-block">
                  <span class="">{{
                    "Partial Amount" | translate
                    }}</span>
                  :
                  <input type="number" (input)="partialChange($event, invoice)" [(ngModel)]="partial_amount"
                    [placeholder]="'0.00' | translate" formControlName="partial_amount" />
                  {{ " LE " | translate }}
                  <span *ngIf="partialForm.controls.partial_amount.touched">
                    <p class="status-danger" *ngIf="
                        partialForm.controls.partial_amount.errors?.pattern
                      ">
                      {{
                      "Partial Amount should be positive number" | translate
                      }}!
                    </p>
                    <p class="status-danger" *ngIf="
                        partialForm.controls.partial_amount.value >=
                        invoice.amount
                      ">
                      {{
                      "Partial Amount should be less than Total Amount"
                      | translate
                      }}!
                    </p>
                  </span>
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="">{{ "Period" | translate }}:</span>
                  {{ invoice?.start_date }} -
                  {{ invoice?.end_date }}
                </p>

                <p *ngIf="invoice?.contract_name " class="main-font mid-font font-weight-bold">
                  <span class="">{{
                    "Contract Name" | translate
                    }} :</span>

                  {{ invoice?.contract_name }}
                </p>
                <div class="row">
                  <div class="col-md-12">
                    <p *ngIf="invoice?.owner " class="main-font mid-font font-weight-bold">
                      <span class="">{{
                        "Payed To" | translate
                        }}:</span>

                      {{ invoice?.owner }}
                    </p>
                    <p *ngIf="!invoice?.contract_name" class="main-font mid-font font-weight-bold">
                      <span class="">{{
                        "Description" | translate
                        }} :</span>

                      {{ invoice?.description }}
                    </p>
                  </div>
                  <div class="col-md-12 partial">
                    <input type="checkbox"  name="pay" id="partial" [(ngModel)]="partialChecked"
                      (change)="onChangeShow($event)" />
                    <label for="partial" class="rent font-weight-bold">
                      {{ "Use partial payment" | translate }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-5 col-sm-12 offset-lg-0 ar-text-right" *ngIf="invoices.length> 0">
      <div class="main-border px-5 pt-3">
        <p class="summary ">{{ "Payment Options" | translate }} :</p>
        <div class="sec-container">
          <!-- <div class="row justify-content-between">
            <div class="col-sm-6 d-flex align-items-center">
              <input type="radio" name="method" id="credit" value="credit" (change)="methodChange('credit')" />
              <img class="px-2 w-25"
                src="assets/images/knet-logo-2360944FA2-seeklogo.com.png" />
              <label class="main-font grdlbl" for="credit">
                {{ "KNET" | translate }}
              </label>
            </div>
            <div class="col-sm-5 d-flex justify-content-end">
            </div>
          </div> -->

          <div class="row justify-content-start py-1">
            <div class="col-sm-12 d-flex align-items-center justify-content-start p-0">
              <div class="d-flex flex-row pr-3 pl-3">
                <input
                type="radio"
                name="method"
                id="credit"
                value="credit"
                (change)="methodChange('credit')"
              />
              <img class="px-2 w-50-icon credit" src="assets/icons/Card.svg" />
              </div>
              <label class="main-font grdlbl" for="credit">
                {{ "Credit/Debit/Meeza card" | translate }}
              </label>
            </div>
            <div class="col-sm-5 d-flex justify-content-end"></div>
          </div>
          <div class="row justify-content-start py-1">
            <div class="col-sm-12 d-flex align-items-center justify-content-start p-0">
            <div class="d-flex flex-row pr-3 pl-3">
              <input
              type="radio"
              name="method"
              id="fawry"
              value="fawry"
              (change)="methodChange('fawry')"
            />
            <img class="px-2 w-50-icon" src="assets/images/fawry.png" />
            </div>
              <label class="main-font grdlbl" for="credit">
                {{ "Fawry payment" | translate }}
              </label>
            </div>
            <div class="col-sm-5 d-flex justify-content-end"></div>
          </div>
          <!-- <div class="row justify-content-between py-1">
            <div class="col-sm-12 d-flex align-items-center justify-content-start p-0">
             <div class="d-flex flex-row pr-3 pl-3">
              <input
              type="radio"
              name="method"
              id="installment"
              value="installment"
              (change)="methodChange('installment')"
            />
            <img class="px-2 w-50-icon" src="assets/icons/installment.svg" />
             </div>
              <label class="main-font grdlbl" for="credit">
                {{ "Pay with Installment" | translate }}
              </label>
            </div>
            <div class="col-sm-5 d-flex justify-content-end"></div>
          </div> -->
          <!-- <div class="row mgbtm">
            <div class="col-sm-1">
              <input
                type="radio"
                name="method"
                id="installment"
                value="installment"
                (change)="methodChange('installment')"
              />
            </div>
            <div class="col-sm-6">
              <label class="main-font mid-font grdlbl nowrap" for="installment">
                {{ "Pay with Installment" | translate }}
              </label>
            </div>
            <div class="col-sm-5">
              <img
                class="{{
                  lang === 'en' ? 'float-right' : 'float-left'
                }} pr-3 bank"
                src="../../../assets/icons/icon_banks.svg"
                alt=""
              />
            </div>
          </div> -->

          <!-- <div
            *ngIf="payment_method === 'installment'"
            class="container d-flex"
          >
            <button
              (click)="select_installment($event,'contact')"
              [ngClass]="{ selected_value: install_value === 'contact' }"
              class="month-div w-25"
            >
              <img class="w-100" src="../../../assets/icons/Contact Logo.svg">
            </button>
          </div> -->
        </div>
        <hr />
        <p class="summary ">{{ "Payment Summary" | translate }} :</p>
        <div class="container font-weight-bold">
          <div *ngIf="partialChecked || partialCheckedShow" class="row">
            <div class="col-12 text-black mid-font pl-4">
              {{ "You are about to pay" | translate }}
              <span class="col-orange">{{
                formateService.formatNum(total)
                }}</span>
              {{ " LE " | translate }}
              {{ "out of" | translate }}
              <span class="col-orange">
                {{ formateService.formatNum(total_invoice_amount) }}
              </span>
              {{ " LE " | translate }}
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-black mid-font pl-4">
              {{ "Subtotal" | translate }}
            </div>
            <div class="col-6 text-black mid-font text-right pr-5">
              {{ formateService.formatNum(subtotal) }} {{ " LE " | translate }}
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-black mid-font pl-4">
              {{ "Discount" | translate }}
            </div>
            <div class="col-6 text-black mid-font text-right pr-5">
              {{ formateService.formatNum(total_discount) }}
              {{ " LE " | translate }}
            </div>
          </div>
          <div *ngIf="partialChecked || partialCheckedShow" class="row">
            <div class="col-6 text-black mid-font pl-4">
              {{ "Remaining" | translate }}
            </div>
            <div class="col-6 text-black mid-font text-right pr-5">
              {{ formateService.formatNum(total_invoice_amount - total) }}
              {{ " LE " | translate }}
            </div>
          </div>

          <div class="row">
            <div class="col-6 text-black mid-font pl-4">
              {{ "Fees" | translate }}
            </div>
            <div class="col-6 mid-font text-black text-right pr-5">
              {{ formateService.formatNum(total_fees) }} {{ " LE " | translate }}
            </div>
          </div>
          <hr />
          <div class="row total">
            <div class="">
              <p class="text-white">{{ "Total Amount" | translate }}</p>
            </div>
            <div class=" mid-font text-white fw-100">
             <span class=" money">{{ formateService.formatNum(finalTotal) }}</span> 
              <span class="fw-100">{{ " LE " | translate }}</span>
            </div>
          </div>
          <div class="row terms">
            <!-- <div class="col-1">
                <input type="checkbox" id="terms" #terms />
              </div> -->
            <div class="col-10 d-flex">
              <input type="checkbox" value="policy" (change)="checkPolicy($event)" class="mx-2" id="terms" />
              <label for="terms">
                <p>
                  "{{
                  "By clicking Pay Now button, you agree that you checked our "
                  | translate
                  }}
                  <a routerLink="/privacy-policy" target="_blank">{{ "Payment Policy" | translate }}"</a>
                </p>
              </label>
            </div>
          </div>
          <div class="row pt-3 checkout-btns">
            <div class="desktopview">
              <a routerLink="/"><button class="btn text-white cancel font-weight-bold">
                  {{ "Cancel" | translate }}
                </button></a>
              <button class="btn text-white checkout font-weight-bold" (click)="checkout()" [disabled]="
                  total <= 0 ||
                  payment_method === null ||
                  !partialForm.valid ||
                  !policyChecked||  (payment_method !=='credit' )
                ">
                {{ "Pay Now" | translate }}
              </button>
            </div>
            <div class="mobileview">
              <button class="btn text-white checkout font-weight-bold" (click)="checkout()" [disabled]="
                  total <= 0 ||
                  payment_method === null ||
                  !partialForm.valid ||
                  !policyChecked||  (payment_method !=='credit' )
                ">
                {{ "Pay Now" | translate }}
              </button>
              <a routerLink="/"><button class="btn text-white cancel font-weight-bold">
                  {{ "Cancel" | translate }}
                </button></a>
            </div>
          </div>
        </div>
      </div>
      <div class="main-border">
        <div class="d-flex justify-content-around p-3 align-items-center">
          <p class="mid-font text-black ml-3 font-weight-bold">
            {{ "Contact an expert to support you" | translate }}
          </p>
          <a href="tel:+201096504044" class="contact-link">
            <button class="btn text-white checkout font-weight-bold">
              {{ "Contact us" | translate }}
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>